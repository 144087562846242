import './styles.css'

function IndexRoute() {
    return(
        <div className='index-route'>
            Click on each tile to know more better...
        </div>
    )
}

export default IndexRoute;
